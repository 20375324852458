import React from 'react';
import Card from "react-bootstrap/Card";
import { InfoSquare } from 'react-bootstrap-icons';
import HomePage from "../HomePage";
import re from  "../../img/react.png"
import '../../css/HomePage.css'


class ModernTech  extends React.Component{
    constructor(props) {
        super(props);

    }


    render() {
        return(
            <Card>
                <Card.Body>
                    <Card.Title> <img src={re} className="carIconMargin"/> Modern technológiák!</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Mint a nagyok!</Card.Subtitle>
                    <Card.Text>
                        A legmondernebb technológiákat használjuk az összes megoldáshoz!
                        Hasznád te is azokat a technológiákat mint a nagy cégek.
                        Ez a technológia révén emelheted a céged vagy a saját weboldalad szinvonalát!
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

}
//<a href="https://iconscout.com/icons/react" target="_blank">React Icon</a> on <a href="https://iconscout.com">Iconscout</a>
export default ModernTech;
