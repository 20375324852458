import React from 'react';


class Szoveg  extends React.Component{
    constructor(props) {
        super(props);

    }
//

    render() {
        return(

            <div className={"container"} style={{marginTop:30}}>
                <h1 className={"text-center text-justify"}>{this.props.cim}</h1>
                    <p className={"text-center text-justify"}>{this.props.leiras} </p>
            </div>


        )
    }

}

export default Szoveg;
