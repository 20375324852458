import React from 'react';

import '../../css/PriceTabel.css'


class CardLeft  extends React.Component{
    constructor(props) {
        super(props);

    }
//

    render() {
        return(

            <div className={"container-fluid bodyColor"} style={{marginTop:30}}>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <img src={this.props.icon} className="img-fluid mx-auto d-block" />
                    </div>

                    <div className={"col-md-8 text-center text-justify"}>
                        <h1  style={{marginTop:60}}>Szervezd ki az üzleti logikád!</h1>
                        <p className={"textlabel"}> Tervezéstől a kivitelezésig! </p>
                        <p className={" textlabel"}>Mindenki számára egyedi megoldást kinálunk az adatbázistól kezdve a szerver oldali logikán keresztül.</p>
                        <p className={"text-center text-justify textlabel"}>Egyedi tervezésü rendszerek,kiszolgálók minden méretre a kezdő válalkozásoktól a nagy válalkozásokig.</p>
                        <h3 className={"text-center text-justify "}>Egyedi tervezés mindenki számára</h3>
                    </div>

                </div>
            </div>


        )
    }

}

export default CardLeft;
