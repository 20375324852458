import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import "../css/navigation.css"


class Navigation extends React.Component {
render(){
    return(

        <Navbar sticky="top" expand="lg" className="navigation-color" variant="dark">
            <Navbar.Brand as={Link} to="/">MikroFelhő</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="mr-auto">
                    <NavItem>
                        <Nav.Link as={Link} to="/" >Főoldal</Nav.Link>
                    </NavItem>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
}
/*
                    <NavItem >
                        <Nav.Link as={Link} to="/Component" >Csomagok</Nav.Link>
                    </NavItem> */

export default Navigation;
