import React from 'react';

import felgojobb from  "../img/FelhoLogo.png"
import Button from 'react-bootstrap/Button'
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import ParticlesBg from 'particles-bg'
import '../css/HomePage.css'
import PriceCard from "./HomePageComponents/PriceCard";
import FastHelp from "./HomePageComponents/FastHelp";
import ModernTech from "./HomePageComponents/ModernTech";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

class PriceTable extends React.Component {

render() {
    return(
<>
             <div className={"container"}>
                 <h1>Az árak</h1>
             </div>
</>
            )
}

}
export default PriceTable;
