import React from 'react';
import Card from "react-bootstrap/Card";
import { Cash } from 'react-bootstrap-icons';


class PriceCard  extends React.Component{
    constructor(props) {
        super(props);

    }


    render() {
        return(
            <Card>
                <Card.Body>
                    <Card.Title><Cash></Cash> Kedvező árak! </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Mindenki számára kedvező árak</Card.Subtitle>
                    <Card.Text>
                        A csomagjaink úgy lettek kitalálva hogy mindenki számra megfelelő szolgáltalást nyujtsanak!
                        Ha nem találod a csomagok között a neked valót vedd fel velünk a kapcsolatot és meg tervezzük az igényeidnek megfelelőt!
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

}

export default PriceCard;
