import React from 'react';
import Card from "react-bootstrap/Card";
import { InfoSquare } from 'react-bootstrap-icons';
import HomePage from "../HomePage";


class FastHelp  extends React.Component{
    constructor(props) {
        super(props);

    }


    render() {
        return(
            <Card>
                <Card.Body>
                    <Card.Title><InfoSquare></InfoSquare> 24h </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Minden felmerülő kérdésre válaszolunk</Card.Subtitle>
                    <Card.Text>
                        Bármi kérdés felmerül 24h belül megválaszoljuk! Ha szerkeszteni szeretné az oldalát nincs más dolga mint ránk bízni. Minden csomag tartalmazza a havi rendszerreségü oldal karbantartást és estleges szerkesztést!
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

}

export default FastHelp;
