import React from 'react';
class footerC extends React.Component {
    render() {
        return(
                <h1>Proba</h1>
        )
    }

}
export default footerC
