import React from 'react';
import Container from "react-bootstrap/Container";
import '../css/footer.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
library.add(fab,faEnvelope)

class TestComponent extends React.Component {

render() {
    return(<footer>
            <Container>
               <Row className="footer-borderbootom">
                   <Col md={3} className="text-center footer-margin"><h1>MikroFelhő</h1></Col>
                   <Col md={9} className="align-middle my-auto d-flex justify-content-md-center justify-content-lg-end  align-content-sm-center">
                       <a href={"http://google.com"}><FontAwesomeIcon icon={["fab", "facebook-square"]} size="3x" className="icons"/> </a>
                       <Link to={"/Component"}><FontAwesomeIcon icon={['fas', 'envelope']} size="3x" className="icons"/></Link>
                   </Col>
               </Row>
                <Row className="footer-borderbootom">
                    <Col md={4}>
                        <h4>Menü</h4>
                        <ul>
                            <li><Link>Főoldal</Link></li>
                            <li><Link>Árak és szolgáltatások</Link></li>
                            <li><Link>kapcsolatok</Link></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4>Kapcsolat</h4>
                        <ul>
                            <li><Link>Főoldal</Link></li>
                            <li><Link>Árak és szolgáltatások</Link></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h4>Tájékozatók</h4>
                        <ul>
                            <li><Link>Főoldal</Link></li>
                        </ul>
                    </Col>

                </Row>
                <Row className="text-center align-items-center">
                    <Col>
                    <p className="setmidle" align="center">
                        Készítette Hegedüs Mózes
                    </p>
                    </Col>
                </Row>

            </Container>
        </footer>)
}


}
export default TestComponent
