import React from 'react';

import felgojobb from  "../img/FelhoLogo.png"
import Button from 'react-bootstrap/Button'
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import ParticlesBg from 'particles-bg'
import '../css/HomePage.css'
import PriceCard from "./HomePageComponents/PriceCard";
import FastHelp from "./HomePageComponents/FastHelp";
import ModernTech from "./HomePageComponents/ModernTech";
import CardLeft from "./HomePageComponents/CardLeft";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom";
import servericon from  "../img/carttonserver.gif"
import Nav from "react-bootstrap/Nav";
import Szoveg from "./HomePageComponents/Szoveg";
import footerC from "./footerC";
import TestComponent from "./Component";



class HomePage extends React.Component {
    componentDidMount() {
        let imageList = [felgojobb,servericon]
        imageList.forEach((image) => {
            new Image().src = image
        });
    }
render() {

    return(
<>
                <div className="container-fluid backgorundColor d-none d-lg-block" style={{paddingBlock:50,marginBlockEnd:50}}>
                    <div className="row">
                    <ParticlesBg color="#ffffff" type="cobweb" bg={false} style={{paddingBlockEnd:100}}/>
                    <img src={felgojobb} className="mx-auto d-block img-fluid centered"/>
                    </div>
                </div>

                <div className="container-fluid backgorundColor d-lg-none " style={{paddingBlock:300,marginBlockEnd:50}}>
                    <div className="row">

                        <img src={felgojobb} className="mx-auto d-block img-fluid centered"/>
                    </div>
                </div>
                    <Szoveg cim={"Bízd ránk a weboldalad"} leiras={""}></Szoveg>
                    <div className="d-lg-none container">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Kedvező árak!
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body><PriceCard/></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    24h
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body><FastHelp/></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    Modern technológiák!
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body><ModernTech/></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    </div>
                    <div className="container d-none d-lg-block">
                        <div className="row">
                            <div className="col">
                                <PriceCard/>
                            </div>
                            <div className="col">
                                <FastHelp/>
                            </div>
                            <div className="col">
                                <ModernTech/>
                            </div>
                        </div>

                    </div>
                     <CardLeft icon={servericon}>
                     </CardLeft>

    <div className={"container"}>
        <Button variant="primary" size="lg" block style={{marginTop:15,marginBlockEnd:30}} as={Link} to="/Component">Tovább a részletekhez!</Button>
    </div>


</>
            )
}

}
export default HomePage;
