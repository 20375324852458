import logo from './logo.svg';
import './App.css';
import Button from 'react-bootstrap/Button'
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import {Link} from "react-router-dom";
import Navigation from "./Components/Navigation";
import MainSwitcher from "./Components/MainSwitcher";
import footerC from "./Components/footerC";
import TestComponent from "./Components/Component";


function App() {
  return (
      <>
            <Navigation></Navigation>
            <MainSwitcher></MainSwitcher>
            <TestComponent></TestComponent>
      </>
  );
}

export default App;
