import React from 'react';
import {
    Switch,
    Route
} from "react-router-dom";

import HomePage from "./HomePage";
import PriceTable from "./PriceTable";
import footerC from "./footerC";

class MainSwitcher extends React.Component {


render() {
    return (
        <div>
        <Switch>
            <Route exact path="/">
            <HomePage/>
            </Route>
            <Route path="/Component">
            <PriceTable/>
            </Route>
            <Route path="*">
                <HomePage/>
            </Route>
        </Switch>

        </div>

    )
}
}
export default MainSwitcher;
